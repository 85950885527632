.botaoLogo {
  padding: 0;
  width: -moz-fit-content;
  width: fit-content;
}

.paginaAplicativos {
  & > img {
    width: 85px;
    height: auto;
  }
}

.submenu {
  z-index: 3;
}

.icone {
  width: 85px;
}

.linkDropdown {
  color: var(--cor-secundaria) !important;

  &.active {
    color: var(--cor-primaria) !important;
    opacity: 0.5;
  }

  &:hover:not(.active) {
    color: #212121 !important;
  }
}

.senhaDescricao {
  margin-bottom: 0px;
  margin-top: 0px;
}

.formulario {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

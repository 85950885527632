.formulario__input_erro {
  input {
    border-color: rgba(241, 11, 11, 0.5) !important;
  }
}

.temSubtitulo {
  margin-bottom: 4px !important;
}

.subtitulo {
  margin-bottom: 8px;
}

.labelContainer {
  & > .obrigatorio::after {
    content: '*';
    color: var(--cor-erro);
    margin-left: 1px;
  }
}

.larguraTotal {
  width: 100%;
  max-width: 100%;
}
